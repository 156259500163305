import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import Cookies from 'js-cookie';

import { EncryptDecryptService } from '../encrypt-decrypt/encrypt-decrypt.service';
import { IUser } from '../classes/user.class';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggedInUserService {
  constructor(
    private encryptDecryptService: EncryptDecryptService,
    private httpClient: HttpClient
  ) {
    this.loggedInUser = this.getLoggedInUser();
  }

  public $showCountDown = new Subject<any>();
  public $usePayEvent = new Subject<any>();
  public loggedInUserUpdated$: Subject<any> = new Subject<any>();
  public loggedInUser: IUser;
  public language: any;
  public primaryColor: any;
  public reservationInfo: any;
  public urlProfile = environment.apiUrl + 'profile';
  public urlAgencies = environment.apiUrl + 'auth/agency-by-mail';
  public flags: any[] = [
    { name: 'Español', image: 'assets/images/flags/es.png', lang: 'es' },
    { name: 'English', image: 'assets/images/flags/en.png', lang: 'en' },
    { name: 'Ruso', image: 'assets/images/flags/ru.png', lang: 'ru' }
  ];
  public hotelId: any;

  public getAgenciesByMail(email: string) {
    const base64EncodedPw = btoa(email);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + base64EncodedPw
      })
    };

    return this.httpClient.get<any>(this.urlAgencies, httpOptions);
  }

  public getLoggedInUser(): IUser {
    let user: string;

    if ('user' in localStorage) {
      user = localStorage.getItem('user');
    } else if ('user' in Cookies) {
      user = (Cookies as any).get('user');
    }

    return user ? JSON.parse(this.encryptDecryptService.decrypt(user)) : null;
  }

  public setAgencyUser(agency) {
    if (agency) {
      const stringAgency = JSON.stringify(agency);
      localStorage.setItem('agency', stringAgency);
      Cookies.set('agency', stringAgency);
    }
  }

  public getAgencyUser(): any {
    let agency: string;

    if ('agency' in localStorage) {
      agency = localStorage.getItem('agency');
    } else if ('agency' in Cookies) {
      agency = (Cookies as any).get('agency');
    }

    return agency ? JSON.parse(agency) : null;
  }

  public setLoggedInUser(user: IUser) {
    this.loggedInUser = user;
    if (user) {
      const dataString = JSON.stringify(user);
      const hashedPass = this.encryptDecryptService.encrypt(dataString);
      localStorage.setItem('user', hashedPass);
      Cookies.set('user', hashedPass);
    }
    this.loggedInUserUpdated$.next(this.loggedInUser);
  }

  public setAccountOfUser(authorization: string): void {
    if (authorization) {
      const account: string = this.encryptDecryptService.encrypt(authorization);
      localStorage.setItem('account', account);
      Cookies.set('account', account, { sameSite: 'Lax', secure: false, domain: environment.domain });
    }
  }

  public getAccountUser(): string {
    let account: string;

    if ('account' in localStorage) {
      account = localStorage.getItem('account');
    } else if ('account' in Cookies) {
      account = (Cookies as any).get('account');
    }

    return account ? this.encryptDecryptService.decrypt(account) : null;
  }

  public setHotelId(id) {
    this.hotelId = id;
  }

  public getHotelId() {
    return this.hotelId;
  }

  public setLanguage(language: string) {
    this.language = language;
  }

  public getLanguages() {
    return this.flags;
  }

  public getLanguage() {
    return this.language;
  }

  public updateUserProfile(user): void {
    this.loggedInUser.avatar = user?.avatar;
    this.loggedInUser.image = user?.image;
    this.loggedInUser.name = user?.name;
    this.loggedInUser.lastname = user?.lastName;
    this.loggedInUser.cellphone = user?.cellphone;
    this.loggedInUser.username = user?.username;
    this.loggedInUser.address = user?.address;
    const dataString = JSON.stringify(user);
    const hashedPass = this.encryptDecryptService.encrypt(dataString);
    localStorage.setItem('user', hashedPass);
    Cookies.set('user', hashedPass);
    this.loggedInUserUpdated$.next(this.loggedInUser);
  }

  public logout(): void {
    this.loggedInUser = null;
    localStorage.removeItem('user');
    localStorage.removeItem('account');
    localStorage.removeItem('agency');
    Cookies.remove('user');
    Cookies.remove('account');
    Cookies.remove('agency');
    this.setLoggedInUser(null);
  }

  public getProfile() {
    let httpParams: HttpParams = new HttpParams();

    httpParams = httpParams.append('skip', 'true');

    return this.httpClient.get<any>(this.urlProfile, {
      params: httpParams
    });
  }

  public getReservationInfo() {
    return this.reservationInfo;
  }

  public setPrimaryColor(data: string): void {
    this.primaryColor = data;
  }

  public getPrimaryColor() {
    return this.primaryColor;
  }

  public UrlAfterLogin(): string|boolean {
    const user = this.loggedInUser

    if (user?.canListHotels) {
      return '/backend-hotels/hotels-list';
    }
    if (user?.canListContractNational  || user?.canListContract) {
      return '/backend-hotels/hotels-contracts';
    }
    if (user?.canListBackendReservations) {
      return '/backend-hotels/hotels-reservations';
    }
    if (user?.canListUsers) {
      return '/backend-hotels/users';
    }
    if (user?.canListCalendar) {
      return '/backend-hotels/hotels-calendar';
    }
    if (user?.canListBackendSearch || user?.canListBackendSearchNational) {
      return '/backend-hotels/backend-search';
    }
    if (user?.canListAgencies) {
      return '/backend-hotels/hotels-agencies';
    }
    if (user?.canListOffer) {
      return '/backend-hotels/ofertas';
    }
    if (user?.canListRegion) {
      return '/backend-hotels/sucursal';
    }
    if(user?.canListPlace){
      return '/backend-hotels/place'
    }
    if(user?.canListOfferCode){
      return '/backend-hotels/offer-code'
    }
    if(user?.canListBedTypes){
      return '/backend-hotels/hotel-bed-types'
    }
    if(user?.canListBedroomQualification){
      return '/backend-hotels/hotel-bedroom-qualification'
    }
    if(user?.canListGeneralBedroomType){
      return '/backend-hotels/hotel-general-bedroom-type'
    }
    if(user?.canListHotelChains){
      return '/backend-hotels/hotel-chains'
    }
    if(user?.canListCategory){
      return '/backend-hotels/reason-for-trip'
    }
    if (user?.canListSettings) {
      return '/backend-hotels/agency-integration';
    }
    if (user?.canListLogs) {
      return '/backend-hotels/logs';
    }

    return false;
  }
}
